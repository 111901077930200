/* body : */
body {
    background-color: #F5F9FC;
    /* font-family: 'Proxima'; */
}
/* container login : */
.container {
    height: 100vh;
}
/* login page : */
.LoginButton {
    background-color: #FF9F2F;
    padding: 12px 30%;
}
.ButtonParent {
    padding: 30px 0px 0px 0px;
}
.logo {
    /* background-color: aqua; */
    padding: 40px 0px;
}
.formCard {
    background-color: white;
    padding: 3rem;
    border-radius: 0.5rem;
    margin-bottom: 1.5rem;
    box-shadow: 0px 3px 6px #00000029;
}
.form {
    width: 100%;
}
.snackbarContainer {
    width: 100%;
}
/* sideBar logo style : */
.logoBoxSideBar {
    display: flex;
    justify-content: center;
    padding: 20% 0px 20% 0px;
    width: 50%;
}
.logoSideBar {
    width: 10rem;
}
.logOut {
    position: fixed;
    bottom: 2rem;
}
/* bord styles : */
.bordPageTitle {
    font-size: 38px;
    font-weight: bold;
}
.bordHeader {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    /* margin-bottom: 2%; */
}
.bordNotificationIcon {
    background-color: #28B873;
    color: white;
    border-radius: 50%;
    font-size: 56px;
    padding: 6px;
}
.bordToastNotification {
    background-color: red;
    color: white;
    /* height: 100px; */
    width: 24%;
    display: inherit;
    padding: 10px 16px;
    border-radius: 10px;
}
.bordToastSpanNotification {
    font-size: 17px;
    font-weight: bold;
}
.bordGlobalContainer {
    padding: 0 4%;
    width: 100%;
}
.bordParentContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    /* padding: 0px 20px; */
}
.bordCardContainer {
    /* display: flex; */
    /* flex-direction: row; */
    /* justify-content: space-between; */
    background-color: white;
    border-radius: 10px;
    margin: 40px 0px;
    box-shadow: 0px 2px 10px 4px rgba(0,0,0,0.4);
}
.bordItemCard {
    border-radius: 8px;
}
.bordInCardContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.bordCardTitle {
    font-size: 16px;
    font-weight: 400;
}
.bordCardIcon {
    color: #28B873;
    font-size: 26px;
}
.bordCardContainerNumber {
    margin-top: 10px;
}
.bordCardNumber {
    font-size: 34px;
    font-weight: 400;
    word-spacing: 10px;
}
.bordCardTableHeader {
    padding: 20px 20px 0px 20px;
}
.bordTableTitle {
    font-size: 20px;
}
.bordStoreTableTitle {
    font-size: 20px;
    font-weight: bold;
}
.statusButton {
    padding: 4px 10px;
    width: 90%;
    border: none;
    border-radius: 20px;
}
.actionButton {
    padding: 8px 10px;
    /* width: 80%; */
    width:145px;
    border: none;
    border-radius: 20px;
    background-color: #28B873;
}
.statusText {
    font-size: 14px;
    color: white;
}
.lineTitle {
    background-color: #28B873;
    border: 1px solid #28B873;
}
.bordTableTitleDivider {
    position: absolute;
    margin: 2px 0px 0px 6px;
}
.bordCardBody {
    /* margin-top: 16px; */
}
.bordTableCellStat {
    /* color: red; */
}
.bordTableCell {
    /* background-color: #FF9F2F; */
    /* padding: 40px; */
    /* border-bottom: 2px solid #28B873; */
}
.rowTableCellWait {
    background-color: #f0f2f3;
}
.bordTableGlobal {
    border: none;
    border-collapse: separate;
    border-spacing: 0px 10px;
}
/* store config : */
.storeConfigHeader {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 6px 20px 6px 10px;
}
.verticalLine {
    background-color: gray;
    /* border: 1px solid black; */
    height: 100px;
}
.storeInfoDetails {
    display: flex;
    flex-direction: column;
    margin: 0px 0px 0px 40px;
}
.storeInfo {
    display: flex;
    flex-direction: row;
    align-items: center;
}
.storeImageConfig {
    border-radius: 50%;
    width: 110px;
    height: 110px;
}
.storeConfigName {
    font-size: 18px;
    font-weight: bold;
}
.storeConfigAdd {
    font-size: 14px;
}
.storeConfigStatus {
    display: flex;
    flex-direction: row;
    width: 40%;
}
.storeButtonStatus {
    margin: 28px 50px 28px 100px;
}
.storeConfigSubTitle {
    font-size: 18px;
    font-weight: bold;
}
.storeConfigSecond {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    /* width: 98%; */
    /* text-align-last: inherit; */
}
.storeConfigSecondHours {
    width: 60%;
}
.storeConfigSecondTime {
    width: 40%;
}
.storeTimePreparation {
    display: flex;
    flex-direction: column;
    align-items: center;
    /* padding: 40px; */
    height: 272px;
}
.storeButtonEditTime {
    background-color: #28B873;
    border-radius: 20px;
    width: 34%;
    margin: 18px 0 0 0;
}
.storeBoxDays {
    height: 300px;
    padding: 0px 0px 20px 20px;
    border-radius: 6px;
}
.storeBoxHoursGrid {
    display: flex;
    flex-direction: column;
    align-items: center;
    /* height: 300px; */
    justify-content: center;
}
.storeBoxHours {
    /* padding: 0px 0px 20px 20px; */
    /* height: auto; */
    /* padding: 24% 0px 24% 0px; */
    border-radius: 6px;
}
.tempsPreparation {
    font-size: 16px;
    font-weight: 400;
    color: gray;
}
.storeTagBox {
    padding: 20px;
}
.storeTagButton {
    width: 24%;
    border-radius: 20px;
    /* background-color: white; */
    color: #28B873;
    border: 2px solid #28B873;
    padding: 10px 0px;
    margin: 10px 0px 10px 10px;
}
/* porduct configuration : */
.tableSubProduct {
    table-layout: fixed;
    width: 100%;
    margin: 0 0 0 6px;
}

@media only screen and (max-width: 1474px) {
   
    .contentinProcess{
        width: 500px;
    }
  }